import React, { useState } from 'react';

const DetailsDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  const title = props.isHeader ? <h4 style={{ fontSize: "2rem" }}><b>{props.title}</b></h4> : props.title;
  const tab = props.isTab ? 'tab' : '';
  
  return (
    <details style={{ fontSize: '15px' }} className={tab}>
      <summary style={{ cursor: 'pointer', userSelect: 'none', display: 'flex', alignItems: 'center' }} onClick={toggleDetails}>
        <span style={{ marginRight: '5px', transition: 'transform 0.3s', transform: `rotate(${isOpen ? 90 : 0}deg)` }}>
          ▶
        </span>
        {title}
      </summary>
      {props.children}
    </details>
  );
}

export default DetailsDropdown;