import React from "react";
import { Typography } from "@mui/material";

import { StudySummaryPageEditor } from "./StudySummaryPageEditor.js";
import { StudySummaryManagement } from "./StudySummaryManagement.js";

const studySummaryDropdownInst =
  "<details>\n  <summary> Validation Data Set </summary>\n\n  - CHD#######\n  - CHD#######\n  - CHD#######\n</details>";

const StudySummaryPage = (props) => {
  return (
    <React.Fragment>
      <>
        <h3 style={{ marginTop: "0px" }} className="fs-3">Instructions</h3>
        <Typography sx={{ marginLeft: '5px', marginTop: '20px', paddingTop: '0.5', fontSize: 16 }} variant='h5'>
          <b>Steps to Add a Study to the Front Page</b>
        </Typography>
        <p>1. Go to "Study Summary Management" section and press the "plus" button to add a new
          study.
        </p>

        <p>2. Fill out Header, Link, and About sections. Please ensure Link only contains
          alphanumerics, dashes, and underscores.
        </p>

        <p>3. Go to "Study Summary Editor" section and select the study summary you want to edit from the
          dropdown. Edit as wanted.
        </p>

        <p>3a. To add a dropdown, just copy the section below and replace the IDs.
        </p>

        <textarea style={{ marginBottom: "40px" }} readonly rows="7" cols="40">{studySummaryDropdownInst}</textarea>
      </>
      <StudySummaryManagement authToken={props.authToken} logoutCallback={props.logoutCallback} />
      <StudySummaryPageEditor authToken={props.authToken} logoutCallback={props.logoutCallback} />
    </React.Fragment>
  );
}

export default StudySummaryPage;