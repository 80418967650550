/*
 * Contributors: Fred Lionetti, Jonathan Smith, Karl Hernandez
 *
 * Libraries used: 
 *
 * File description: Contains the majority of functions which help with the functionality
 * of the app. Main component that is called in index.js
 */

// import { MyTable } from "./components/table";
// import { IdentifyStudies } from "./components/identify_studies";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import { FaceOutlined } from "@material-ui/icons";
// import Content from "./components/Paperbase/Content";

import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import React, { useMemo } from "react";
import { useCallback } from "react";
import AWS from "aws-sdk";
import { Landing_Page } from "./components/landing_page";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Paperbase from "./components/Paperbase/Paperbase";
import Papa from "papaparse";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { Routes, 
  Route} from "react-router-dom";
import { urls, tableNames } from "./components/constants";
import $ from 'jquery';
import { Button, Typography } from "@mui/material";
import Content from './components/Paperbase/Content.js';
import { Amplify } from 'aws-amplify';
import StudyUpload from "./components/Paperbase/StudyUpload";
import CSVUpload from "./components/Paperbase/CSVUpload";
import CSVTemplates from './components/Paperbase/CSVTemplates'
import StudyDownload from "./components/Paperbase/StudyDownload";
import StudiesInS3 from "./components/Paperbase/StudiesInS3";
import { IdentifyStudies } from "./components/identify_studies";
import RenderTables from "./components/Paperbase/RenderTables";
import { UploadStatus } from "./components/Paperbase/UploadStatus";
import MissingData from "./components/Paperbase/MissingData";
import ResetStageTables from "./components/Paperbase/ResetStageTables";
import CreateUser from "./components/Paperbase/CreateUser";
import { StudySummaryManagement } from "./components/Paperbase/StudySummaryManagement.js";
import StudyDelete from "./components/Paperbase/StudyDelete";
import AttachmentUpload from "./components/Paperbase/AttachmentUpload";
import AttachmentDownload from "./components/Paperbase/AttachmentDownload";
import { UsersTable } from "./components/Paperbase/UsersTable";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { DataGridPro } from "@mui/x-data-grid-pro";
import ReactLoading from 'react-loading';
import WebLoader from './components/web_loader';

import { Box } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ListItem, List } from '@mui/material';

import StudySummary from "./components/Paperbase/StudySummary.js";
import DataUsageAgreementPage from "./components/Paperbase/DUA/DataUsageAgreementPage.js";
import StudySummaryPage from "./components/Paperbase/StudySummaryPage.js";

function ConfirmDialog({ title, note, question, files, open, setOpen, upload_callback, reject_callback }) {
  const handleYes = () => {
    upload_callback();
    setOpen({ open: false });
  };

  const handleNo = () => {
    reject_callback();
    setOpen({ open: false });
  };

  return (
    <Dialog 
        maxWidth="xl"
        open={open}
        sx={{".MuiDialogContent-dividers": {color: "white"}}}>
        <DialogTitle sx={{fontSize: 16, backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid" }}>{title}</DialogTitle>
        <DialogContent dividers sx={{backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid"}}>
            <p>{question}</p>
            {note && <p>{note}</p>}
            <Box sx={{ maxHeight: '300px', overflow: 'auto'}}>
                <List>
                    {(files || []).map((file, index) => (
                        <ListItem key={index} sx={{color: "white"}}>{file}</ListItem>
                    ))}
                </List>
            </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#081627", }}>
            <Button onClick={handleNo} sx={{color: "white", fontSize: 14}}>
                No
            </Button>
            <Button onClick={handleYes} autoFocus sx={{color: "white", fontSize: 14}}>
                Yes
            </Button>
        </DialogActions>
    </Dialog>
  );
}


//import awsconfig from './aws-exports';
const base_download_url = urls.base_download_url;
const base_url = urls.base_url;
const base_url_query_table = urls.base_url_query_table;
const identify_pool_id = urls.identify_pool_id;
const cognito_url = urls.cognito_url;
const study_summary_url = `${base_url}/study_summary`;

const study_bucketName = urls.study_bucketName;
const csv_bucketName = urls.csv_bucketName;
const region = "us-west-1";
$.csv = require('jquery-csv');
const pages = new Map([
  ["single_patient", ["Single Patient", "Single Patient"]],
  ["single_study", ["Single Study", "Single Study"]],
  ["Study_upload", ["Study Folders", "Upload Studies"]],
  ["Study_download", ["Study Folders ", "Download Study Folders"]],
  ["link_studies", ["Link Study to CMR Entry", "Link Study to CMR Entry"]],
  ["CSV_upload", ["CSV Tables", "Upload CSV Tables"]],
  ["single_patient", ["Single Patient", "Single Patient"]],
  ["CMR", ["CMR", "CMR - Cardiovascular Magnetic Resonance Imaging"]],
  ["CPET", ["CPET", "CPET - Cardiopulmonary Exercise Test"]],
  ["Procedure", ["Procedure", "Procedure"]],
  ["CATH", ["CATH", "CATH - Cardiac Catheterization"]],
  ["ECG", ["ECG", "ECG - Electrocardiogram"]],
  ["ECHO", ["ECHO", "ECHO - Echocardiogram"]],
  ["Clinical_Notes", ["Clinical Notes", "Clinical Notes"]],
  ["CSV_upload_status", ["CSV Upload Status", "Upload File Status"]],
  ["CSV_templates", ["CSV Templates", "CSV Templates"]],
  ["missing_data", ["Missing Data", "Missing Data"]],
  ["reset_tables", ["Reset Stage Tables", "Reset Stage Tables"]],
  ["create_user", ["Create User", "Create User"]],
  ["users", ["Users", "Users"]],
  ["uploaded_studies", ["Uploaded Studies", "Uploaded Studies"]],
  ["delete_study_folder", ["Delete Study Folder", "Delete Study Folder"]],
  ["attachment_upload", ["Attachment Upload", "Clinical Notes Attachments"]],
  ["attachment_download", ["Attachment Download", "Clinical Notes Attachments "]],
  ["dataAgreement_upload", ["Data Use Agreement Upload", "Data Use Agreements"]],
  ["Database", ["Patients", "Patients"]],
])

var in_flight = 0;

const allowed_colummns = {
  "cath": ["ID", "age", "Pre LV ESP", "Pre LV EDP", "Pre RV ESP", "Pre RV EDP", "Pre PA ESP", "Pre PA EDP", "Pre Systolic AP", "Pre Diastolic AP", "Pre Cardiac Index", "Post LV ESP", "Post LV EDP", "Post RV ESP", "Post RV EDP", "Post PA ESP", "Post PA EDP", "Post Systolic AP", "Post Diastolic AP", "Post Cardiac Index", "note"], 
  "cmr": ["ID", "age", "Height", "Weight", "BSA", "Study", "Study Type", "LV CO", "LV CO index to BSA", "LV Mass", "LV Mass index to BSA", "LV stroke volume", "LVEDV", "LVEDV index to BSA", "LVESV", "LVESV index to BSA", "LVEF", "RV Cardiac Output", "RV CO index to BSA", "RV EDV", "RV EDV index to BSA", "RV ESV", "RV ESV index to BSA", "RV Mass", "RV Mass index to BSA", "RV stroke volume", "RV EF", "note"],
  "cpet": ["ID", "age", "VO2 L/MIN MEASURED", "VO2 MEASURED VALUE @ AT", "O2 PULSE PEAK MEASURED", "VE/VCO2 @ AT MEASURED", "note"], 
  "ecg": ["ID", "age", "Heart Rate", "Vent Rate", "PR Interval", "QRS Duration", "QTc Interval", "QRS axis", "note"], 
  "echo": ["ID", "age", "Height", "Weight", "BSA", "IVS", "LVEDD", "LVESD", "LVPW", "LA", "Tricuspid Regurgitation", "TAPSE", "Bullet LVEF", "RVOT/PS Gradient", "note"], 
  "patient": ["ID", "Cohort", "Ethnicity", "Race", "Sex"], 
  "procedure": ["ID", "age", "Procedure", "note"],
  "clinicalnotes": ["ID", "age", "note", "File Attachments"],
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const isValidPatientId = (patient_id) => {
  const idRegExp = /^[C][H][D][0-9][0-9][0-9][0-9][0-9]$/;
  return idRegExp.test(patient_id);
};

const isValidStudyId = (study_id, patient_id="") => {
  patient_id = patient_id ? patient_id : "[C][H][D][0-9][0-9][0-9][0-9][0-9]";
  const idRegExp = new RegExp(`^${patient_id}[0-9][0-9]$`);
  return idRegExp.test(study_id);
};

/**
* Check correct filename and folder setup for Study image folders
*
* @params file path
* @return patient id, study id, and remaining path if correct else null
*/
function identifier(p) {
  const path_array = p.split('/');
  
  let i = 0;
  console.log(path_array);

  // removes empty space at start of path
  while(path_array[i] === "" && i < path_array.length) {
    path_array.shift();
    i++;
  }

  if (path_array == null || !isValidPatientId(path_array[0])) return null;
  if (!isValidStudyId(path_array[1], path_array[0])) return null;
  
  return [path_array[0], path_array[1], path_array.slice(2).join("/")];
}

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const defaultFilterOptions = [
  {
    id: 'Cohorts',
    children: [
      { id: 'ToF'},
      { id: 'LVH/HCM'},
      { id: 'HLHS'},
      { id: 'Normal Heart'},
      { id: 'Pulmonary HTN'},
      { id: 'ASD'},
    ],
  },
  {
    id: 'Ethnicity',
    children: [
      { id: 'White'},
      { id: 'Hispanic'},
      { id: 'Asian'},
      { id: 'African'},
      { id: 'Pacific Islander'},
      { id: 'American Indian or Alaska Native'}
    ],
  },
  {
    id: 'Race',
    children: [
      { id: 'White'},
      { id: 'Hispanic'},
      { id: 'Asian'},
      { id: 'Black or African American'},
      { id: 'Pacific Islander'},
      { id: 'American Indian or Alaska Native'}
    ],
  },
  {
    id: 'Sex',
    children: [
      { id: 'M'},
      { id: 'F'},
    ],
  },
];

function parseJwt (token) {
  if(token === null) {
    return "";
  }
  //console.log(token)
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  let result = JSON.parse(jsonPayload);
  //console.log(result);
  result = result['cognito:groups'];
  if(result === null || result === undefined) {
    return "error"
  }
  let groups = result;
  return groups;
};

function parseJwtUsername (token) {
  if(token === null) {
    return "";
  }
  //console.log(token)
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  let result = JSON.parse(jsonPayload);
  result = result['username'];
  return result;
};

const setDefaultToggleOpen = () => {
  let openTables = {
    "CMR": false,
    "CPET": false,
    "Cath": false,
    "ECG": false,
    "ECHO": false,
    "Patient": false,
    "Procedure": false,
    "missing_studies": false,
    "linking_studies": false,
  };
  return openTables;
}

const urlAccessTokens = (navigate) => {
  var url_string = window.location.href.replace("#", "?")
  var url = new URL(url_string);
  var url_auth_token = url.searchParams.get("access_token");
  let url_id_token = url.searchParams.get("id_token");
  if(url_auth_token != null && url_id_token != null) {
    localStorage.setItem("url_auth_token", url_auth_token);
    localStorage.setItem("url_id_token", url_id_token);
    navigate("/Database");
    return [url_auth_token, url_id_token];
  } else {
    return [localStorage.getItem("url_auth_token"), localStorage.getItem("url_id_token")];
  }

}

const defaultSelectedIndex = () => {
  let currentIndex = localStorage.getItem("selectedIndex");
  if(currentIndex != null) {
    return currentIndex;
  } else {
    return "Patients"
  }
}

// consider moving to constants file
const defaultStoredFilters = {
  "filters":{},
  "requirestudies":false,
  "age":[],
  "customids":[],
}

// functions for retrieving stored filter
const getStoredFilters = () => {
  try {
    return JSON.parse(localStorage.getItem("filter")) ?? defaultStoredFilters;
  } catch (e) {
    return defaultStoredFilters;
  }
}

/**
* Main function of file that contains majority of functions for api calls
* 
*
* @params None
* @return renders paperbase website or frontpage depending on user authorization
*/
function App() {
  const classes = useStyles();
  const [patient_id, set_patient_id] = useState("");
  const [studyId, setStudyId] = useState("");
  const [studyInfo, setStudyInfo] = useState({});
  const [studyInfoLoading, setStudyInfoLoading] = useState(false);
  //console.log(url_string);
  const navigate = useNavigate();
  let tokens = urlAccessTokens(navigate);
  var url_auth_token = tokens[0];
  let url_id_token = tokens[1];
  let id_token = url_id_token;
  const database_check_url = `${base_url_query_table}?cmd=check_database_integrity`;
  const location = useLocation();
  const [authToken, setAuthtoken] = useState(url_auth_token);
  const [studyCount, setStudyCount] = useState("<loading>");
  const [uploadCount, setUploadCount] = useState(0);
  const [rejectedUploads, setRejectedUploads] = useState([]);
  const [failedUploads, setFailedUploads] = useState([]);
  const [numAcceptedFiles, setNumAcceptedfiles] = useState(0);
  const [uploadCountCSV, setUploadCountCSV] = useState(0);
  const [rejected_uploads_stateCSV, setRejectedUploadsCSV] = useState(0);
  const [failed_uploads_stateCSV, setFailedUploadsCSV] = useState(0);
  const [numAcceptedCSVFiles, setNumAcceptedCSViles] = useState(0);
  const [landingPageData, setLandingPageData] = useState({});
  let [headerTitle, setHeaderTitle] = useState("Patients");
  const [downloadLoad, setDownloadLoad] = useState("<done>");
  const [userGroup, setUserGroup] = useState([]);
  const [table, setTable] = useState("");
  const [files, setFiles] = useState([]);
  const [category_name, set_category_name] = useState("DICOM");
  const [csvFile, setCSVFile] = useState([]);
  const [csvGoodFile, setCSVGoodFile] = useState(false);
  const [csvKey, setCSVKey] = useState("");
  const [studyKey, setStudyKey] = useState(new Set());
  const [username, setUsername] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [ageError, setAgeError] = useState(0);
  const [idError, setIDError] = useState(0);
  const [chdError, setCHDError] = useState(0);
  const [ymError, setYMError] = useState(0);
  const [editing, setEditing] = useState(false);
  const [tofAtlas, setTofAtlas] = useState(false);
  const [loaded, setLoaded] = useState(0);
  const hiddenFileInput = React.useRef(null);
  const [missingData, setMissingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleOpen, setToggleOpen] = useState(setDefaultToggleOpen());
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex());
  const [collapseNav, setCollapseNav] = useState(false);
  const [openOverwrite, setOpenOverwrite] = useState(false);

  const [dialogState, setDialogState] = useState({ open: false, title: '', question: '', note: '', files: [] });

  // States for AttachmentUpload.js
  const [patientIDAttachmentUpload, setPatientIDAttachmentUpload] = useState("");
  const [validIDAttachmentUpload, setValidIDAttachmentUpload] = useState(false);
  const [agesAttachmentUpload, setAgesAttachmentUpload] = useState([]);
  const [ageAttachmentUpload, setAgeAttachmentUpload] = useState("");

  // filter state variables
  const [showFilter, setShowFilter] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);

  const [filters, setFilters] = useState(getStoredFilters());
  const [activeFilters, setActiveFilters] = useState(getStoredFilters());

  // study summary state variables
  const [studySummaryList, setStudySummaryList] = useState([]);
  
  Amplify.configure({
    Auth: {
      identityPoolId: 'us-west-1:3b1e11b4-ae3a-40a7-8f67-9f173b90ed3f',
      region: 'us-west-1',
      userPoolId: 'us-west-1_1Nvwa50B4',
      userPoolWebClientId: '33pko4bpf1qrsdkqidi4mmarsh',
    },
  });

  // Handle trying to visit specific links while not logged in
  useEffect(() => {
    // When the user is not logged in, save where they wanted to go and redirect them to the login page
    if(!authToken) {
      if(window.location.hash !== "" && window.location.hash !== "#/") {
        localStorage.setItem("desiredLocation", window.location.hash);
        window.location.href = urls.login_url;
      }
    }
    // After the user logs in, take them to wherever they were trying to get to before
    else if(localStorage.getItem("desiredLocation") !== null) {
      const newLocation = localStorage.getItem("desiredLocation");
      localStorage.removeItem("desiredLocation");
      window.location.hash = newLocation;
    }
  })
  
  useEffect(() => {
    localStorage.setItem("filter", JSON.stringify(getStoredFilters()));
    let result = parseJwt(authToken);
    let username = parseJwtUsername(authToken);
    //console.log(result);
    if(result === "error") {
      alert("You are not part of a Cognito group. Logging out");
      forceLogout(null)
    }else {
      setUserGroup(result);
      setUsername(username);
      //console.log("username:", username);
    }
    //console.log(authToken);
    setLandingPageData(JsonData);
  }, []);

  useEffect(() => {
    fetch(study_summary_url, {headers:{}})
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setStudySummaryList(data.sort((a, b) => a["ID"] - b["ID"]));
    }).catch(err=>{
        console.log("Failed to fetch study summary:", err);
    }, [])
  }, []);

  /*
  * checks if uploaded csv file has proper filename
  *
  * Param (p): filename
  * return: true if file has correct filename and false otherwise
  */
  function identifier_csv(p, columns, data) {
    if(table === "") {
      return 1;
    }
    let table_keys = allowed_colummns[table];
    for(let i = 0; i < data.length; i++) {
      if(Object.keys(data[i]).length === table_keys.length) {
        columns = Object.keys(data[i]);
        break;
      }
    }
    //console.log(table_keys);
    //console.log(columns);
    let sym_diff = table_keys
                    .filter(key => !columns.includes(key))
                    .concat(columns.filter(key => !table_keys.includes(key)))
    if(sym_diff.length > 0){
      console.log(sym_diff)
      return 2;
    }

    let ageError = 0;
    let idError = 0;
    let chdError = 0;
    let ymError = 0;
    let headerNumber = table_keys.length;
    for(let i = 0; i < data.length; i++) {
      let row = data[i];
      let rowHeaders = Object.keys(row).length;
      let rowValues = Object.values(row);
      let firstVal = rowValues[0];
      let validRow = false;
      for(let i = 0; i < rowValues; i++) {
        if(firstVal !== rowValues[i]) {
          validRow = true;
          break;
        }
      }
      //console.log("rowHeaders:", rowHeaders);
      if(rowHeaders > 1 && validRow) {
        if(row.age !== undefined) {

          {/*
          if(row.age.includes("m")) {
            const userKeyRegExp = /^([0-9]|[1-9][0-9]|100)[y]\-([0-9]|[1][0-1]|100)[m]$/;
            const valid = userKeyRegExp.test(row.age);
            if(!valid) {
              ymError += 1;
            }
          } else {
            const userKeyRegExp = /^([0-9]|[1-9][0-9]|100)[y]$/;
            const valid = userKeyRegExp.test(row.age);
            if(!valid) {
              ymError += 1;
            }
          }
          */}
          const ageRegExp = /\d+/gm;
          const validAge = [...row.age.matchAll(ageRegExp)]
          //console.log("Age check regular expression:", validAge);
          if(validAge.length <= 0) {
            ymError += 1;
          } else {
            let year = parseInt(validAge[0][0]);
            if(year < 0 || year > 120) {
              ymError += 1;
            } else if(validAge.length > 1) {
              let month = parseInt(validAge[1][0]);
              if(month < 1 || month > 11){
                ymError += 1;
              }
            } 
          }
        }
        //console.log("row length:", row.size)
        if(row.ID === "") {
          idError += 1;
        } else {
          const idRegExp = /^[C][H][D][0-9][0-9][0-9][0-9][0-9]$/;
          const validID = idRegExp.test(row.ID);
          if(!validID && row.ID != "") {
            chdError += 1;
          }
        }
    
        if(table !== "patient" && row.age === "") {
          ageError += 1;
        }
      }
      
    }
    //console.log("Age errors: ", ageError);
    //console.log("id errors: ", idError);
    if(ageError > 0 && idError === 0) {
      setAgeError(ageError);
      //console.log("Age errors: ", ageError)
      return 5;
    } else if(ageError === 0 && idError > 0) {
      setIDError(idError);
      return 3;
    } else if(ageError > 0 && idError > 0) {
      setIDError(idError);
      setAgeError(ageError);
      return 6;
    } else if (chdError > 0) {
      setCHDError(chdError);
      return 4;
    } else if(ymError > 0) {
      setYMError(ymError);
      return 7;
    }
    return 0;
  }

  // filter functions
  const FilterUpdate = (refresh) => {
    localStorage.setItem("filter", JSON.stringify(filters));
    if(refresh) setLoaded(loaded + 1);
    handleActiveFilters();
  }

  const handleActiveFilters = () => {
    let storedFilters = getStoredFilters();

    setActiveFilters({...storedFilters});
  }

  const listToObject = ( values) => {
    //console.log(values);
    let valueArray = [];
    for(let i = 0; i < values.length; i++) {
      valueArray.push(
        {
          id: values[i]
        }
      )
    }
    return valueArray;
  }

  const handleFilterOptions = (authToken) => {
    let filterOptions_url = `${base_url_query_table}?cmd=get_patient_fields`;
    fetch(filterOptions_url, {headers:{"Authorization":authToken}})
      .then(response => response.json())
      .then(data => {
          console.log("Filter Options", data)
          let result = [];
          let categories = Object.getOwnPropertyNames(data);
          for(let i = 0; i < categories.length; i++) {
            result.push(
              {
                id: categories[i],
                children: listToObject(data[categories[i]])
              }
            );
          }
          //console.log(result);
          if(result.length === 0) {
            result = defaultFilterOptions;
          }
          //console.log(result);
          setFilterOptions(result);
      })
      .catch(err=>{
        //console.log("Failed to fetch filter options:", err);
        return 0;
      });
  }

  useEffect(() => {
    //console.log("Loaded app!:");
    if (authToken) handleFilterOptions(authToken);
  }, [authToken]);

  useEffect(() => {
    let filterString = JSON.stringify(filters);
    try {
      filterString = JSON.stringify(JSON.parse(filterString));
    } catch (e) {
      filterString = null;
    }

    let storedFilter = localStorage.getItem("filter");

    if(filterString && storedFilter !== filterString && showFilter !== 2) {
      setFilters(getStoredFilters());
      alert("Filter Changes have not been saved. Filters will not apply on table refresh.");
    }
  }, [showFilter]);

  const handleAlertClose = (event) => {
    setOpenAlert(0);
  };

  function csv_checks(file) {
    // Initialize a reader which allows user to read any file or blob.
    const reader = new FileReader();
    let goodFile = true;
    let bucketName = csv_bucketName;
    let successful_uploads_CSV = uploadCountCSV;
    let failed_uploads_CSV = failed_uploads_stateCSV;
    let rejected_uploads_CSV = rejected_uploads_stateCSV;
    //console.log(file.path);
    setCSVKey(file);
      
    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true });
      const parsedData = csv?.data;
      const columns = csv.meta.fields;
      //console.log("Found CSV file:", file.path);
      //console.log(parsedData);
      //console.log(table);
      let result = identifier_csv(file.path, columns, parsedData);

      if(result !== 0) {
        switch(result) {
          case 2:
            setOpenAlert(3);
            break;

          case 3:
            setOpenAlert(9);
            break;

          case 4:
            setOpenAlert(10);
            break;

          case 5:
            setOpenAlert(11);
            break;

          case 6:
            setOpenAlert(12);
            break;

          case 7:
            setOpenAlert(13);
            break;

          default:
            setOpenAlert(4);
            break;
        }

        goodFile = false;
        rejected_uploads_CSV += 1;
        setRejectedUploadsCSV(rejected_uploads_CSV);
        let csvArray = files.map((file) => (
          <li style={{fontSize: 16, display: "flex", marginTop: 15}} key={file.name}>
            <Button style={{ pointerEvents: "none",backgroundColor: "CAE8F5", height: 47, width: 100, borderRadius: "50%", border: "#009BE5", borderStyle: "solid", marginRight: 4}}>
              CSV
            </Button>
            <Typography style={{fontSize: 16, marginBlockStart: 10}}>
              {file.name} - {file.size} bytes
            </Typography>
            <CloseIcon sx={{fontSize: 32 }} style={{marginLeft: 20, marginBlockStart: 5, color: "red" }}></CloseIcon>
          </li>
        ));
        setCSVFile(csvArray);
        setCSVGoodFile(false);
      }
      
      if(goodFile) {
        let today = new Date();
        let time = "";
        if((today.getMonth() + 1) < 10) {
          time = "0" + (today.getMonth() + 1);
        } else {
          time = today.getMonth() + 1;
        }

        if(today.getDate() < 10) {
          time = time + "-0" + today.getDate(); 
        } else {
          time = time + "-" + today.getDate();
        }

        time = time + "-" + today.getFullYear();

        if(today.getHours() < 10) {
          time = time + "-0" + today.getHours(); 
        } else {
          time = time + "-" + today.getHours();
        }

        if(today.getMinutes() < 10) {
          time = time + "-0" + today.getMinutes(); 
        } else {
          time = time + "-" + today.getMinutes();
        }

        if(today.getSeconds() < 10) {
          time = time + "-0" + today.getSeconds(); 
        } else {
          time = time + "-" + today.getSeconds();
        }

        let tableName = tableNames[table];
        let key = username + "-" + time + "_" + tableName + ".csv";
        
        //console.log(key);
        //console.log("AWS.config:" , AWS.config)
        
        // upload CSV
        var upload = new AWS.S3.ManagedUpload({
          params: { Bucket: bucketName, Key: key, Body: file },
        });
        var promise = upload.promise();
        promise.then(
          function (data) {
            successful_uploads_CSV += 1;
            setUploadCountCSV(successful_uploads_CSV);
            //console.log("uploaded CSV!");
            setOpenAlert(1);
            let csvArray = files.map((file) => (
              <li style={{fontSize: 16, display: "flex", marginTop: 15}} key={file.name}>
                <Button style={{pointerEvents: "none", backgroundColor: "CAE8F5", height: 47, width: 100, borderRadius: "50%", border: "#009BE5", borderStyle: "solid", marginRight: 4}}>
                  CSV
                </Button>
                <Typography style={{fontSize: 16, marginBlockStart: 10}}>
                  {file.name} - {file.size} bytes
                </Typography>
                <DoneIcon sx={{fontSize: 32 }} style={{marginLeft: 20, marginBlockStart: 5, color: "#009be5" }}></DoneIcon>
              </li>
            ));
            setCSVFile(csvArray);
            setCSVGoodFile(true);
          },
          function (err) {
            setOpenAlert(2);
            //console.log(
            //   "Failed to upload (js)",
            //   file.name,
            //   "with error:",
            //   err.message,
            //   "Config: ",
            //   AWS.config
            // );
            failed_uploads_CSV += 1;
            let csvArray = files.map((file) => (
              <li style={{fontSize: 16, display: "flex", marginTop: 15}} key={file.name}>
                <Button style={{pointerEvents: "none", backgroundColor: "CAE8F5", height: 47, width: 100, borderRadius: "50%", border: "#009BE5", borderStyle: "solid", marginRight: 4}}>
                  CSV
                </Button>
                <Typography style={{fontSize: 16, marginBlockStart: 10}}>
                  {file.name} - {file.size} bytes
                </Typography>
                <CloseIcon sx={{fontSize: 32 }} style={{marginLeft: 20, marginBlockStart: 5, color: "red" }}></CloseIcon>
              </li>
            ));
            setCSVFile(csvArray);
            setFailedUploadsCSV(failed_uploads_CSV);
            setCSVGoodFile(false);
          } 
        );
      }
      
    }
    
    reader.readAsText(file);
  };

  /* 
      const csvUploadConfirm = () => {
    let bucketName = csv_bucketName;
    let successful_uploads_CSV = uploadCountCSV;
    let failed_uploads_CSV = failed_uploads_stateCSV;
    let file = csvKey;

    let today = new Date();
    let time = (today.getMonth() + 1) + "-" + today.getDate() + "-" + today.getFullYear() + "-" + today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    let key = "";
    if(table !== "patient" && table !== "procedure") {
      key = username + "-" + time + "_" + table.toUpperCase() + ".csv";
    } 
    else {
      let tableName = table.substring(0, 1);
      tableName = tableName.toUpperCase() + table.substring(1, table.length);
      key = username + "-" + time + "_" + tableName + ".csv";
    }
    
    //console.log(key);
    
    // upload CSV
      var upload = new AWS.S3.ManagedUpload({
        params: { Bucket: bucketName, Key: key, Body: file },
      });
      var promise = upload.promise();
      promise.then(
        
        function (data) {
          successful_uploads_CSV += 1;
          setUploadCountCSV(successful_uploads_CSV);
          //console.log("uploaded CSV!");
          alert("Successfully uploaded: " + file.name);
          setCSVGoodFile(false);
        },
        function (err) {
          alert("Failed to upload: " + file.name);
          //console.log(
          //   "Failed to upload (js)",
          //   file.name,
          //   "with error:",
          //   err.message
          // );
          failed_uploads_CSV += 1;
          setFailedUploadsCSV(failed_uploads_CSV);
          setCSVGoodFile(false);
        }

        
      );
  }
  */

  if (patient_id != "") {
    let study_count_url = `${base_url}/count_studies?patient_id=${patient_id}`;
    fetch(study_count_url, {headers:{"Authorization":authToken}})
      .then(response => response.json())
      .then(data => {
        //console.log("study count?", data)
        setStudyCount(data);
      }).catch(err=>{
        forceLogout(null);
        //console.log("Failed to fetch study count:", err);
      });
  }
/*
  if(navTabs === 0){
    let summary_url = `https://3fikwhtk3i.execute-api.us-west-1.amazonaws.com/Stage/do_get_summary_table`;
        fetch(summary_url, {headers:{"Authorization":authToken}})
        .then(response => response.json())
        .then(data => {
            console.log("summary", data)
        }).catch(err=>{
        console.log("Failed to fetch summary:", err);
      });
  }
*/
  

/** 
    let populate_summary_url = `${base_url_query_table}?cmd=populate_summary`;
    fetch(populate_summary_url, {headers:{"Authorization":authToken}})
    .then(response => response.json())
    .then(data => {
        //console.log("summary:", data)
    }).catch(err=>{
    //console.log("Failed to fetch total:", err);
  });
  */


  /**
  * Function Description
  *
  * @params parameters
  * @return return value 
  */
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      console.log("acceptedFiles:", acceptedFiles);
      setOpenAlert(21); //analyzing files message
    }
    
    AWS.config.region = region;
    let logins = {};
    logins[cognito_url] = id_token;
    AWS.config.region = region; // Region

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identify_pool_id,
        Logins: logins
    });

    // Make the call to obtain credentials
    AWS.config.credentials.get(function(){
        // Credentials will be available when this function is called.
        var accessKeyId = AWS.config.credentials.accessKeyId;
        var secretAccessKey = AWS.config.credentials.secretAccessKey;
        var sessionToken = AWS.config.credentials.sessionToken;
        AWS.config.update({ region:region, credentials: new AWS.Credentials(accessKeyId, secretAccessKey)});
    });

    let num_files_to_upload = acceptedFiles.length;
    setRejectedUploads([]);
    setFailedUploads([]);
    setUploadCount(0);
    setNumAcceptedfiles(num_files_to_upload);
    setFiles(acceptedFiles);
    setStudyKey(new Set());
  }, []);

  const csvUpload = (e) => {
    if(e !== undefined) {
      
      const files = Array.from(e.target.files)
      e.target.value = null;
      //console.log("files:", files)
      let csvArray = files.map((file) => (
        <li style={{fontSize: 16, display: "flex", marginTop: 15}} key={file.name}>
          <Button  style={{pointerEvents: "none",backgroundColor: "CAE8F5", height: 47, width: 100, borderRadius: "50%", border: "#009BE5", borderStyle: "solid", marginRight: 4}}>
            CSV
          </Button>
          <Typography style={{fontSize: 16, marginBlockStart: 10}}>
            {file.name} - {file.size} bytes
          </Typography>
          <div className="dot-pulse" style={{marginLeft: 25, marginBlockStart: 18 }} />
        </li>
      ));
      setCSVFile(csvArray);

      AWS.config.region = region;
      let logins = {};
      logins[cognito_url] = id_token;
      AWS.config.region = region; // Region
  
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identify_pool_id,
          Logins: logins
      });
  
  
      // Make the call to obtain credentials
      AWS.config.credentials.get(function(){
          // Credentials will be available when this function is called.
          var accessKeyId = AWS.config.credentials.accessKeyId;
          var secretAccessKey = AWS.config.credentials.secretAccessKey;
          var sessionToken = AWS.config.credentials.sessionToken;
          AWS.config.update({ region:region, credentials: new AWS.Credentials(accessKeyId, secretAccessKey)});
      });
  
  
      let num_files_to_upload = files.length;
      setNumAcceptedCSViles(num_files_to_upload);
      setUploadCountCSV(0);
      setFiles(files);
    }
    
  }

  function insertCategoryInPath(path, category) {
    let path_array = path.split('/');
    path_array.splice(2, 0, category);
    return path_array.join('/');
  }

  const upload_usageAgreement = (file, key) => {
    return new Promise((resolve, reject) => {
      // Reauthenticate
      AWS.config.region = region;
      let logins = {};
      logins[cognito_url] = id_token;
      
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identify_pool_id,
        Logins: logins,
      });
      
      AWS.config.credentials.get(() => {
        const accessKeyId = AWS.config.credentials.accessKeyId;
        const secretAccessKey = AWS.config.credentials.secretAccessKey;
        const sessionToken = AWS.config.credentials.sessionToken;
        AWS.config.update({
          region: region,
          credentials: new AWS.Credentials(accessKeyId, secretAccessKey, sessionToken),
        });

        const upload = new AWS.S3.ManagedUpload({
          params: { Bucket: urls.data_usage_agreement_bucketName, Key: key, Body: file },
        });

        upload.promise()
          .then(() => {
            console.log("Upload successful");
            resolve(200); // Success
          })
          .catch((error) => {
            console.error("Error uploading file to S3:", error);
            reject(400); // Failure
          });
      });
    });
  };


  const upload_file = (bucketName, outer_counter, good_files) => {
    // Reauthenticate
    AWS.config.region = region;
    let logins = {};
    logins[cognito_url] = id_token;
    AWS.config.region = region; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identify_pool_id,
      Logins: logins
    });
    AWS.config.credentials.get(function(){
      // Credentials will be available when this function is called.
      var accessKeyId = AWS.config.credentials.accessKeyId;
      var secretAccessKey = AWS.config.credentials.secretAccessKey;
      var sessionToken = AWS.config.credentials.sessionToken;
      AWS.config.update({ region:region, credentials: new AWS.Credentials(accessKeyId, secretAccessKey)});
    });

    let batch_size = 50;

    for (let inner_counter = 0; inner_counter < batch_size; inner_counter++) {
      let counter = outer_counter + inner_counter;
      if (counter >= good_files.length) {
        console.log("All done with files!");
        break;
      }

      let item = good_files[counter];
      let key = item[0];
      let element = item[1];

      // upload CSV or Study data
      console.log("App.js uploading to:", bucketName, key);
  
      var upload = new AWS.S3.ManagedUpload({
      params: { Bucket: bucketName, Key: key, Body: element },
      });
      var promise = upload.promise();
      in_flight++;
      console.log("in flight:", in_flight);
      promise.then(
      function (data) {
        setUploadCount(uc => uc + 1);

        console.log(element.path)
        let chdIndex = element.path.indexOf("CHD");
        let patient_id = element.path.substring(chdIndex, chdIndex + 8);

        // patient_id for precache
        if (/^othernotes/i.test(key)) {
          const elem = key.split('/');
          patient_id = `${elem[0]}/${elem[1]}/${elem[2]}`; // ex. OtherNotes/CHD01900/1Y
          if (in_flight === 1) {
            updateFileAttachmentsCount();
          }
        }

        if(!studyKey.has(patient_id)) {
          setStudyKey(sk => {
            const new_sk = new Set(sk)
            new_sk.add(patient_id);
            return new_sk;
          });
        }
        in_flight--;
        console.log("in flight:", in_flight);

        if (inner_counter == batch_size-1) //if last file for this batch, start a new batch
            upload_file(bucketName, counter+1, good_files);
      },
      function (err) {
        setFailedUploads(fu => [...fu, element.path]);
        in_flight--;
        console.log("in flight:", in_flight);
        if (inner_counter == batch_size-1) //if last file for this batch, start a new batch
            upload_file(bucketName, counter+1, good_files);
      });
    }
  }

  // Update File Attachments count
  function updateFileAttachmentsCount() {
    const update_count_url = `${base_url_query_table}?cmd=modify_file_attachments&patient_id=${patientIDAttachmentUpload}&age=${ageAttachmentUpload}`;

    fetch(update_count_url,{
        method: "GET",
        headers: { 
        'Authorization': authToken,
        'Content-Type': 'application/json' 
        },
    }).then((response) => response.json())
    .then((data) => {
        console.log("Count changed to:", data)
    })
    .catch((err)=>{
        console.log("Updating count failed | error:", err)
    })
  }

 /**
  * Single study lookup
  *
  * @params searchbar component
  * @return None
  */
  const handleLookupStudy = (e) => {
  if (e.keyCode == 13)
  {
      //console.log("value", e.target.value);
      let input = e.target.value;
      if (input.includes(":"))
      {
          localStorage.setItem("filter", input);
          console.log(input);
      }
      else
      {
          if (localStorage.getItem("filter"))
          {
              console.log("Removing_item filter");
              localStorage.removeItem("filter");
          }
          let prefix = input.substring(0, 3);
          let number = input.substring(3, input.length);
          if(prefix !== "CHD" || number.length != 7 || isNaN(number) )
          {
              setOpenAlert(17);
          }
          else
          {
            let studyInfoURL = `${base_url_query_table}?cmd=get_study_info` +
            `&study_id=${e.target.value}`;
            // call api
            setStudyInfoLoading(true);
            fetch(studyInfoURL, { headers: { Authorization: authToken } })
            .then((response) => response.json())
            .then((data) => {
              // if data collected, return data
              let id = e.target.value;
              if(Object.keys(data).length === 0) {
                setOpenAlert(19)
              } else {
                // changes study id string to just Study as file extension
                // this might cause issues if the data does not always return
                // the study id as an attribute
                if(id.length >= 8){
                  let val = id.substring(0, 8) + '/' + id;
                  data["DICOM"] = data[val]
                  delete data[val];
                }

                data = Object.fromEntries(
                  Object.entries(data).map(([key, value]) => [
                  key.substring(key.lastIndexOf('/') + 1),
                  value,
                  ]));

                setStudyInfo(data);
                setStudyId(id);
              }
              setStudyInfoLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setOpenAlert(18);
              setStudyInfoLoading(false);
            });
          }
      }
  }
  }

  const handleCategoryChange = (event) => {
    setFiles([]);
    set_category_name(event.target.value);
  };


  const studyInfoColumns = [
    { field: 'extension', headerName: 'Category', width: 200 },
    { field: 'count', headerName: 'Number of Files', width: 150 },
  ];


  const studyInfoRows = Object.keys(studyInfo).map((extension) => ({
    id: extension,
    extension,
    count: studyInfo[extension],
  }));

  // reset study info when navigating to a different page
  useEffect(() => {
    setStudyInfo({});
    setStudyId("");
    set_patient_id("");
  }, [location]);

      /**
    * Checks filenaming conventions and either rejects or
    * uploads file
    *
    * @params file or folder being uploaded
    * @return None
    */
  useEffect(() => {
    setCSVGoodFile(false);
    if (!files) return;
    let bucketName = study_bucketName;
    let good_files = [];

    files.forEach((element) => {
      if (!element) return;
      
      let key = element.path ? element.path : element.name;
      console.log(key);

      if ((key.endsWith(".csv") || key.endsWith(".CSV")) && table) {
        csv_checks(element);
      } 
      else if (!(/CHD\d{5}\/CHD\d{7}/i.test(key)) && patientIDAttachmentUpload && ageAttachmentUpload) {
        key = `OtherNotes/${patientIDAttachmentUpload}/${ageAttachmentUpload}/${key}`
        console.log(key);
        good_files.push([key, element]);
      }
      else {
        let response = identifier(key); // check if in CHD#####/CHD#######/ format
        if (response === null) {
          setOpenAlert(5);
          setRejectedUploads(ru => [...ru, key]);
          return;
        }

        key = key.replace(/^\//, ""); // removes forward slash at beginning of path if present
        key = category_name === "DICOM" ? key : insertCategoryInPath(key, category_name);

        good_files.push([key, element]);
      }
    });

    let counter = 0;
    if (good_files.length > 20000) {
      setOpenAlert(20);
      setRejectedUploads(good_files.map(([filename, file]) => filename));
    }
    else if (good_files.length > 0){
      checkForDuplicatesAndUpload(bucketName, counter, good_files).then(() => {
        console.log('Finished checking for duplicates and uploading!');
      });
    }
  }, [files, category_name])

  // To clarify, "good" refers to passing name checks
  // "good" files could still be duplicates
  async function checkForDuplicatesAndUpload(bucketName, counter, good_files) {
    let new_file_names = good_files.map(([filename, file]) => filename);
    var fileMap = organize_files(new_file_names);

    let intersections = [];
    let study_intersections = [];
    let new_studies = [];
    let cmd = "get_study_files";

    for (let patient_id in fileMap) {            
      for (let study_name in fileMap[patient_id]) {
        let url = `${base_url_query_table}?cmd=${cmd}&patient_id=${patient_id}&study_name=${study_name}`;

        let response = await fetch(url, {headers:{"Authorization": authToken}});
        let data = await response.json();
        let previous_files_in_study = data.files;

        // If there are any files in patientFileNames, add the study_name to study_intersections
        if (previous_files_in_study.length > 0) 
          if (/^othernotes/i.test(previous_files_in_study)) {
            study_intersections.push(`${patient_id}/${study_name}`); // ex. OtherNotes/CHD01900/1Y
          } else {
            study_intersections.push(study_name);
          }
        else
          new_studies.push(study_name);

        // Find the intersection of the patient's file names with the good file names.
        let patientIntersections = previous_files_in_study.filter(x => new_file_names.includes(x));

        // Add the intersections to our list.
        intersections = intersections.concat(patientIntersections);
      }
    }

    intersections = [...new Set(intersections)]; //remove duplicates from intersections list
    study_intersections = [...new Set(study_intersections)];  //remove duplicates from study_intersections list

    if (intersections.length > 0) {
      console.log("found collisions");

      // intersections occurred of file names and maybe study names also
      // Go head and upload new files
      let new_good_files = good_files.filter(([filename, _]) => !intersections.includes(filename));
      upload_file(bucketName, counter, new_good_files);

      let collision_good_files = good_files.filter(([filename, _]) => intersections.includes(filename));

      var msg;
      let note = "Note: Unprompted files are being uploaded in the background.";
      if (intersections.length === 1)
        msg = `1 file with the same name has been previously uploaded.  The file is listed below.  Do you want to proceed with the upload and overwrite the file?`;
      else
        msg = `${intersections.length} files with the same name have been previously uploaded.  These files are listed below.  Do you want to proceed with the upload and overwrite the existing files?`;

      if (/^othernotes/i.test(intersections[0])) {
        note = "Note: Non-duplicate files will be uploaded in the background.";
      }

      function my_upload_callback() {
        upload_file(bucketName, counter, collision_good_files);
      }

      function my_reject_callback() {
        setRejectedUploads(ru => [...ru, ...intersections]);
      }

      let dialog_params = {open: true, title: 'Upload file duplicates', question: msg, note: note,
                            files: intersections, upload_callback: my_upload_callback,
                            reject_callback: my_reject_callback};
      setDialogState(dialog_params);
    }
    else if (study_intersections.length > 0) {
      console.log("found study intersections...");
      // No file name collisions; but there were study name intersections

      // Identify files that are part of new studies
      let new_good_files_new_studies = [];
      let new_good_files_collision_studies = [];
      let new_files_old_studies = [];

      for (let i = 0; i < good_files.length; i++) {
        let file_info = good_files[i];
        let full_file_name = file_info[0];
        let studyName = full_file_name.split("/");

        if (/^othernotes/i.test(full_file_name)) {
          studyName = `${studyName[0]}/${studyName[1]}/${studyName[2]}`; // ex. OtherNotes/CHD01900/1Y
        } else {
          studyName = studyName[1];
        }
        
        if (study_intersections.includes(studyName)) {
          new_good_files_collision_studies.push(file_info);
          new_files_old_studies.push(full_file_name);
        }
        else {
          new_good_files_new_studies.push(file_info);
        }
      }

      upload_file(bucketName, counter, new_good_files_new_studies);

      var msg;
      let note = "Note: Unprompted files are being uploaded in the background.";
      if (study_intersections.length === 1)
        msg = `1 study with the same name has been previously uploaded but with differnet files.  The new files are listed below.  Do you want to proceed with the upload and add more files to the study?`;
      else
        msg = `${study_intersections.length} studies with the same name have been previously uploaded but with different names.  These new files are listed below.  Do you want to proceed with the upload and add more files to the study?`;

      let title = 'Upload study duplicates';

      if (/^othernotes/i.test(study_intersections[0])) {
        msg = "There are already files for this patient at this age. Do you want to add the following additional files?";
        title = "Upload additional files";
        note = "";
      }

      function my_upload_callback() {
        upload_file(bucketName, counter, new_good_files_collision_studies);
      }

      function my_reject_callback() {
        setRejectedUploads(ru => [...ru, ...new_files_old_studies]);
      }

      let dialog_params = {open: true, title: title, question: msg, note: note,
                            files: new_files_old_studies, upload_callback: my_upload_callback,
                            reject_callback: my_reject_callback};
      setDialogState(dialog_params);
    }
    else {
      // no intersections?  Just upload everything
      upload_file(bucketName, counter, good_files);
    }
  }

  function organize_files(files) {
    let fileMap = {};

    for(let file of files) {
        let parts = file.split('/');
        let patientID;
        let studyName;
        let fileName;

        // if - OtherNotes Upload | else - other file uploads
        if (/^othernotes/i.test(file)) {
          patientID = `${parts[0]}/${parts[1]}`; // OtherNotes/PatientID (ex. OtherNotes/CHD00000)
          studyName = parts[2]; // Patient Age (ex. 1Y)
          fileName = parts.slice(3).join('/'); // this is for handling nested directories within the age
        } else {
          patientID = parts[0];
          studyName = parts[1];
          fileName = parts.slice(2).join('/'); // this is for handling nested directories within the study_name
        }

        // Initialize patient ID map if it doesn't exist
        if(!(patientID in fileMap)) {
            fileMap[patientID] = {};
        }
    
        // Initialize study name map if it doesn't exist
        if(!(studyName in fileMap[patientID])) {
            fileMap[patientID][studyName] = [];
        }
    
        // Push filename to the correct list
        fileMap[patientID][studyName].push(fileName);
    }
    console.log("file map:", fileMap);
    return fileMap;
  }

  useEffect(() => {
    // if processed all files and there are accepted files and there are uploaded files
    if(((uploadCount + failedUploads.length + rejectedUploads.length) === numAcceptedFiles) && numAcceptedFiles > 0 && uploadCount > 0) {
      setOpenAlert(15);
      studyKey.forEach(element => {
        console.log(element);
        let patientID = element;
        let url = base_download_url + `precache=true&patient_ids=` + patientID;
        fetch(url, {headers:{"Authorization":authToken}, method: "GET"});
      })
    }
  }, [uploadCount, failedUploads, rejectedUploads, numAcceptedFiles, studyKey])

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  /**
  * Syling for table
  *
  */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const filesList = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));


  /**
  * Function that sends API request to check if the patient exists
  * in the dynamoDB 'Patient' table.
  *
  * @params searchbar component
  * @return None
  */
 function studyIDExists(study_id) {

    let summary_url = `${base_url_query_table}?cmd=check_if_ID_exists&patient_id=` +
    patient_id;
        fetch(summary_url, {headers:{"Authorization":authToken}})
        .then(response => response.json())
        .then((data) => {
          let boolIDExists = data["id_exists"]
          //console.log("id_exists is: ", boolIDExists);
          if (boolIDExists == false) 
          {
            setOpenAlert(6);
            set_patient_id("");
          }
        }).catch(err=>{
        //console.log("Failed to fetch Single Patient Data", err);
      });
  }

  /**
  * Function that sends API request to check if the patient exists
  * in the dynamoDB 'Patient' table.
  *
  * @params searchbar component
  * @return None
  */
 function patientIDExists(patient_id) {

    let summary_url = `${base_url_query_table}?cmd=check_if_ID_exists&patient_id=` +
    patient_id;
        fetch(summary_url, {headers:{"Authorization":authToken}})
        .then(response => response.json())
        .then((data) => {
          let boolIDExists = data["id_exists"]
          //console.log("id_exists is: ", boolIDExists);
          if (boolIDExists == false) 
          {
            setOpenAlert(6);
            set_patient_id("");
          }
        }).catch(err=>{
        //console.log("Failed to fetch Single Patient Data", err);
      });
  }

/**
  * Function that sends API request to check if the patient exists
  * in the dynamoDB 'Patient' table.
  *
  * @params searchbar component
  * @return None
  */
  function patient_study_intersection(patient_id, study_name, new_files) {
    let cmd = "get_study_files";
    let summary_url = `${base_url_query_table}?cmd=${cmd}&patient_id=${patient_id}&study_name=${study_name}`;
    fetch(summary_url, {headers:{"Authorization":authToken}})
      .then(response => response.json())
      .then((data) => {
          let server_files = data['files'];
          console.log("server files:", server_files);
          console.log("new_files:", new_files);
          let intersection = server_files.filter(file => new_files.includes(file));
          console.log("intersection:", intersection); // Output: ['file3', 'file4']
      });
  }

  /**
  * Single patient lookup
  *
  * @params searchbar component
  * @return None
  */
  function handleLookupPatient(e)
  {
    if (e.keyCode == 13)
    {
        //console.log("value", e.target.value);
        let input = e.target.value;
        if (input.includes(":"))
        {
            localStorage.setItem("filter", input);
        }
        else
        {
            if (localStorage.getItem("filter"))
            {
                //console.log("Removing_item filter");
                localStorage.removeItem("filter");
            }
            let prefix = input.substring(0, 3);
            let number = input.substring(3, input.length);
            if(prefix !== "CHD" || number.length != 5 || isNaN(number) )
            {
                setOpenAlert(7)
            }
            else
            {
                patientIDExists(e.target.value);
                set_patient_id(e.target.value);
            }
        }
    }
  }

  /**
  * logs out user while logout button pressed
  *
  * @params component 
  * @return None
  */
  function logoutKeyPress(e) {
    //console.log("Logout!!!");
    localStorage.removeItem("url_auth_token");
    setAuthtoken(null);
    navigate("/");
  }

  /**
  * Forces logout of user from paperbase
  *
  * @params component 
  * @return None
  */
  function forceLogout(e) {
    //console.log("Force Logout!!!");
    localStorage.removeItem("url_auth_token");
    setOpenAlert(9);
    setAuthtoken(null);
    navigate("/");
  }

  /**
  * Download Studies based on comma seperated list
  *
  * @params text entry component
  * @return None
 
  function downloadKeyPress(e) {
    if (e.keyCode == 13) {
      setDownloadLoad("<loading>");
      //console.log("value", e.target.value);
      let patient_id_list = [];
      let raw_strings = e.target.value.split(",");
      for (let i = 0; i < raw_strings.length; i++) {
        patient_id_list.push(raw_strings[i].trim());
      }
      //console.log("patient_id_list:", patient_id_list);

      let url = "https://gr18htkzab.execute-api.us-west-1.amazonaws.com/PROD?";
      for (let i = 0; i < patient_id_list.length; i++) {
        url += `patient_ids=${patient_id_list[i]}`;
        if (i != patient_id_list.length - 1) url += "&";
      }
      //console.log("Download URL:", url);

      fetch(url, { headers: { Authorization: authToken } })
        .then((response) => response.json())
        .then((data) => {
          //console.log("Download button yielded:", data);
          setDownloadLoad("<done>");
          if (data == "NODATA") {
            setOpenAlert(8);
          } else {
            window.open(data, "_blank");
          }
        });
    }
  }
 */
    

  const logoutCallback = (forced) => { forced? forceLogout(null): logoutKeyPress(null) };

  /**
  * Download Studies based on comma seperated list
  *
  * @params text entry component
  * @return None
  */
  function downloadKeyPress(e) {
    if (e.keyCode == 13) {
      setDownloadLoad("<loading>");
      //console.log("value", e.target.value);
      const idRegExp = /^[C][H][D][0-9][0-9][0-9][0-9][0-9]$/;

      let patientID = e.target.value;
      const validID = idRegExp.test(patientID);
      if(!validID) {
        setOpenAlert(14);
        setDownloadLoad("<done>");
        return;
      }
      console.log("patient_id:", patientID);

      let url = base_download_url;
      url += `patient_ids=${patientID}`;
      
      console.log("Download URL:", url);

      fetch(url, { headers: { Authorization: authToken } })
        .then((response) => response.json())
        .then((data) => {
          //console.log("Download button yielded:", data);
          setDownloadLoad("<done>");
          if (data === "MISSING") {
            setOpenAlert(8);
          } else {
            window.open(data, "_blank");
          }
        })
        .catch(err => {
          setOpenAlert(8);
        });
    }
  }

  const handleToggleOpen = (table) => {
    //console.log(table)
    setToggleOpen({
      ...toggleOpen,
      [table]: !toggleOpen[table]
    });
    //console.log(toggleOpen)
  }

  const handleToggleAll = (toggle) => {
    let newToggle = {...toggleOpen}
    Object.keys(toggleOpen).forEach(key => {
      newToggle[key] = 
        key === 'missing_studies' ? toggleOpen['missing_studies'] :
        key === 'linking_studies' ? toggleOpen['linking_studies'] :
        toggle
    })
    setToggleOpen(newToggle)
  }

  const handleTableSelect = (event) => {
    setTable(event.target.value);
    setPatientIDAttachmentUpload("");
    setValidIDAttachmentUpload(false);
    setAgeAttachmentUpload("");
  };

  const handleCSVClick = (event) => {
    hiddenFileInput.current.click();
  };

  const fetchMissingData = () => {
    fetch(database_check_url, { headers: { "Authorization": authToken } })
    .then((response) => response.json())
    .then((data) => {
      if(data.message === "The incoming token has expired") {
        logoutCallback();
      }
      
      setMissingData(data);

      setLoading(false);

    }).catch(() => {
        //console.log("Failed to fetch!", props.logoutCallback);
        logoutCallback();
    });
  }

  useEffect(() => {
    if (authToken) {
      fetchMissingData()
    }
  }, []);

  const handleListItemClick = (
    event,
    childId
  ) => {
    if(!editing) {
      setSelectedIndex(childId);
      localStorage.setItem("selectedIndex", childId);
    }
    
  };

  const handleLinking = (e, childID) => {
    let value = childID;
    //console.log(childId);

    if(!editing) {
      switch (value) {

        case 'Study Folders': case "Upload Studies": case "Study_upload":
          navigate("Database/Study_upload");
          setHeaderTitle("Upload Study Images");
          setShowFilter(0);
          break;
  
        case 'Study Folders ': case "Download Study Folders": case "Study_download":
          navigate("Database/Study_download");
          setHeaderTitle("Download Study Folders");
          setShowFilter(0);
          break;
  
        case 'Link Study to CMR Entry': case "link_studies":
          navigate("Database/link_studies");
          setHeaderTitle("Link Study to CMR Entry");
          setShowFilter(0);
          break;
  
        case 'Single Patient': case "single_patient":
          navigate("Database/single_patient");
          setHeaderTitle("Single Patient");
          setShowFilter(0);
          break;
  
        case 'CSV Tables': case "CSV_upload":
          navigate("Database/CSV_upload");
          setHeaderTitle("Upload CSV Tables");
          setShowFilter(0);
          break;
  
        case 'CMR': 
          navigate("Database/CMR");
          setHeaderTitle("CMR - Cardiovascular Magnetic Resonance Imaging");
          break;
  
        case 'Procedure': 
          navigate("Database/Procedure");
          setHeaderTitle("Procedure");
          break;
  
        case 'CPET': 
          navigate("Database/CPET");
          setHeaderTitle("CPET - Cardiopulmonary Exercise Test");
          break;
  
        case 'CATH': 
          navigate("Database/CATH");
          setHeaderTitle("CATH - Cardiac Catheterization");
          break;
  
        case 'ECG': 
          navigate("Database/ECG");
          setHeaderTitle("ECG - Electrocardiogram");
          break;
  
        case 'ECHO': 
          navigate("Database/ECHO");
          setHeaderTitle("ECHO - Echocardiogram");
          break;
        
        case 'Clinical Notes': case "Clinical_Notes":
          navigate("Database/Clinical_Notes");
          setHeaderTitle("Clinical Notes");
          break;
  
        case 'CSV Upload Status': case "CSV_upload_status": case "Upload Status":
          navigate("Database/CSV_upload_status");
          setHeaderTitle("Upload File Status");
          setShowFilter(0);
          break;

        case 'CSV Templates': case "CSV_templates":
          navigate("Database/CSV_templates");
          setHeaderTitle("CSV Templates");
          break;
        
        case 'Missing Data': case "missing_data":
          navigate("Database/missing_data");
          setHeaderTitle("Missing Data");
          break;
        
        case 'Reset Stage Tables':
          navigate("Database/reset_tables");
          setHeaderTitle("Reset Stage Tables");
          break;
  
        case 'Create User': 
          navigate("Database/create_user");
          setHeaderTitle("Create User");
          break;
          
        case 'Users': 
          navigate("Database/users");
          setHeaderTitle("Users");
          break;
        
        case 'Study Summary': 
          navigate("Database/study_summary");
          setHeaderTitle("Study Summary");
          break;

        case 'Single Study':
          navigate("Database/single_study");
          setHeaderTitle("Single Study");
          break;

        case 'Uploaded Studies': case 'Uploaded Studies':
          navigate("Database/uploaded_studies");
          setHeaderTitle("Uploaded Studies");
          break;

        case "Delete Study Folder": case "Delete Study Folder":
          navigate("Database/delete_study_folder");
          setHeaderTitle("Delete Study Folder");
          break;

        case "Attachment Upload": case "Clinical Notes Attachments":
          navigate("Database/attachment_upload");
          setHeaderTitle("Clinical Notes Attachments");
          break;

        case "Attachment Download": case "Clinical Notes Attachments ":
          navigate("Database/attachment_download");
          setHeaderTitle("Clinical Notes Attachments");
          break;
        
        case "Data Use Agreement Upload": case "Data Use Agreements":
          navigate("Database/dataAgreement_upload");
          setHeaderTitle("Data Use Agreements");
          break;
  
      default:
          navigate("/Database");
          setHeaderTitle("Patients");
          break;
      }
    }else{
      setOpenAlert(16);
    }


  }

  useEffect(() => {
    var url_string = window.location.href.replace("#", "?");
    let url_array = url_string.split("/");
    let page = url_array[url_array.length - 1 || 0];
    console.log(page);
    if(pages.has(page)) {
      setHeaderTitle(pages.get(page)[1])
      handleListItemClick("", pages.get(page)[0]);
    }
  }, [location])

  return (
    <div className={classes.root}>
      <Routes>
        <Route path="*" element={<WebLoader />} />
        {studySummaryList.map(studySummary => (
          <Route path={studySummary["Link"]} element={<StudySummary study={studySummary["Link"]}/>} />
        ))}
        <Route path="/" element={<Landing_Page data={landingPageData.Landing_Page} />} />
        <Route path="/Database" element={
          <Paperbase
            logoutKeyPress={logoutKeyPress}
            authToken={authToken}
            headerTitle={headerTitle}
            setHeaderTitle={setHeaderTitle}
            userGroup={userGroup}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filters={filters}
            setFilters={setFilters}
            filterOptions={filterOptions}
            FilterUpdate={FilterUpdate}
            ageError={ageError}
            idError={idError}
            chdError={chdError}
            ymError={ymError}
            selectedIndex={selectedIndex}
            handleListItemClick={handleListItemClick}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            setLoaded={setLoaded}
            setCollapseNav={setCollapseNav}
            collapseNav={collapseNav}
            handleLinking={handleLinking} />
        } >
          <Route path="single_patient" element={
            <Content 
              search={handleLookupPatient} 
              set_patient_id={set_patient_id} 
              placeHolderText="“Enter a valid patient ID in the form ‘CHDXXXXX’"
              authToken={authToken} 
              logoutCallback={logoutCallback} 
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              setLoaded={setLoaded}
              loaded={loaded}
              patient_id={patient_id}
              setHeaderTitle={setHeaderTitle}
              userGroup={userGroup}
              FilterUpdate={FilterUpdate} />
          } />
          <Route path="single_study" element={
            <>
              <Content 
                search={handleLookupStudy}
                placeHolderText="Enter a valid study ID in the form ‘CHDXXXXXXX’"
                patient_id={""}/>
              <Typography sx={{marginLeft: '5px', marginTop: '40px', paddingTop: '0.5', fontSize: 16}} variant='h5'>
                <b>Info for study:</b> {studyId}
              </Typography>
              <>
                {studyInfo && Object.keys(studyInfo).length > 0 ?
                  <>
                    <div style={{ marginTop: '10px', height: 400, width: '100%' }}>
                      <DataGridPro rows={studyInfoRows} columns={studyInfoColumns}/>
                    </div>
                  </>
                  : studyInfoLoading ?
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                      <ReactLoading type="spin" color="#009be5" height={667} width={375} />
                    </div>
                  : <></>
                }
              </>
            </>
          } />
          <Route path="Study_upload" element={
            <StudyUpload 
            getRootProps={getRootProps}
            style={style}
            getInputProps={getInputProps}
            uploadCount={uploadCount}
            numAcceptedFiles={numAcceptedFiles}
            rejected_uploads_state={rejectedUploads}
            failed_uploads_state={failedUploads}
            files={files}
            category_name={category_name}
            set_category_name={set_category_name}
            handleCategoryChange={handleCategoryChange}/>
          } />
          <Route path="CSV_upload" element={
            <CSVUpload
            table={table}
            tableNames={tableNames}
            handleTableSelect={handleTableSelect}
            handleCSVClick={handleCSVClick}
            csvUpload={csvUpload}
            hiddenFileInput={hiddenFileInput}
            csvFile={csvFile}
            csvGoodFile={csvGoodFile}
            handleListItemClick={handleListItemClick}
            handleLinking={handleLinking}
            missingData={missingData}
            handleToggleOpen={handleToggleOpen}
            handleToggleAll={handleToggleAll}
            toggleOpen={toggleOpen}
            loading={loading}
            setOpenAlert={setOpenAlert}
            authToken={authToken}/>
          } />
          <Route path="Study_download" element={
            <StudyDownload downloadLoad={downloadLoad} downloadKeyPress={downloadKeyPress}/> 
          } />
          <Route path="link_studies" element={
            <IdentifyStudies authToken={authToken} logoutCallback={logoutCallback}/>
          } />
          <Route index element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.patient}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle}/>
          } />
          <Route path="CMR" element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.cmr}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle} />
          } />
          <Route path="ECHO" element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.echo}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle} />
          } />
          <Route path="CATH" element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.cath}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle} />
          } />
          <Route path="ECG" element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.ecg}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle} />
          } />
          <Route path="CPET" element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.cpet}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle} />
          } />
          <Route path="Procedure" element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.procedure}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle} />
          } />
          <Route path="Clinical_Notes" element={
            <RenderTables 
              activeFilters={activeFilters}
              setShowFilter={setShowFilter}
              setCollapseNav={setCollapseNav}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              allowed_colummns={allowed_colummns}
              FilterUpdate={FilterUpdate}
              userGroup={userGroup}
              setLoaded={setLoaded}
              loaded={loaded}
              singleName={tableNames.clinicalnotes}
              set_patient_id={set_patient_id}
              authToken={authToken}
              logoutCallback={logoutCallback}
              setHeaderTitle={setHeaderTitle} />
          } />
          <Route path="CSV_templates" element={
            <CSVTemplates
              tableNames={tableNames}
              loading={loading}
              setOpenAlert={setOpenAlert}
              authToken={authToken} />
          } />
          <Route path="CSV_upload_status" element={
            <UploadStatus authToken={authToken} logoutCallback={logoutCallback} userName={username}/>
          } />
          <Route path="missing_data" element={
            <MissingData 
              authToken={authToken} 
              logoutCallback={logoutCallback} 
              handleToggleOpen={handleToggleOpen}
              handleToggleAll={handleToggleAll}
              toggleOpen={toggleOpen}
            />
          } />
          <Route path="reset_tables" element={
            <ResetStageTables authToken={authToken} logoutCallback={logoutCallback} />  
          } />
          <Route path="create_user" element={
            <CreateUser authToken={authToken} logoutCallback={logoutCallback} />
          } />
          <Route path="users" element={
            <UsersTable handleListItemClick={handleListItemClick} setEditing={setEditing} setOpenAlert={setOpenAlert} userGroup={userGroup} authToken={authToken} logoutCallback={logoutCallback} handleLinking={handleLinking}></UsersTable>
          } />
          <Route path="study_summary" element={
            <StudySummaryPage
              authToken={authToken}
              logoutCallback={logoutCallback} 
            />
          } />
          <Route path="uploaded_studies" element={
            <StudiesInS3 authToken={authToken} logoutCallback={logoutCallback} />
          } />
          <Route path="delete_study_folder" element={
                <StudyDelete authToken={authToken} logoutCallback={logoutCallback} baseUrl={base_url_query_table} setOpenAlert={setOpenAlert}/> 
          }/>
          <Route path="attachment_upload" element={
            <AttachmentUpload 
              authToken={authToken} 
              logoutCallback={logoutCallback} 
              baseUrl={base_url_query_table} 
              setOpenAlert={setOpenAlert} 
              tableNames={tableNames}
              patientID={patientIDAttachmentUpload}
              setPatientID={setPatientIDAttachmentUpload}
              validID={validIDAttachmentUpload}
              setValidID={setValidIDAttachmentUpload}
              ages={agesAttachmentUpload}
              setAges={setAgesAttachmentUpload}
              age={ageAttachmentUpload}
              setAge={setAgeAttachmentUpload}
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              style={style}
              uploadCount={uploadCount}
              numAcceptedFiles={numAcceptedFiles}
              rejected_uploads_state={rejectedUploads}
              failed_uploads_state={failedUploads}
              setTable={setTable}
            />
          }/>
          <Route path="attachment_download" element={
            <AttachmentDownload 
              authToken={authToken} 
              logoutCallback={logoutCallback}
              setOpenAlert={setOpenAlert} 
              baseQueryUrl={base_url_query_table}
              downloadUrl={base_download_url} 
              tableNames={tableNames}/>
          }/>
          <Route path="dataAgreement_upload" element={
            <DataUsageAgreementPage 
              handleListItemClick={handleListItemClick}
              setEditing={setEditing}
              setOpenAlert={setOpenAlert}
              userGroup={userGroup}
              authToken={authToken}
              logoutCallback={logoutCallback}
              handleLinking={handleLinking}
              baseUrl={base_url}
              bucketName={urls.data_usage_agreement_bucketName}
              uploadToS3={upload_usageAgreement}
              tableNames={tableNames}
              patientID={patientIDAttachmentUpload}
              setPatientID={setPatientIDAttachmentUpload}
              validID={validIDAttachmentUpload}
              setValidID={setValidIDAttachmentUpload}
              ages={agesAttachmentUpload}
              setAges={setAgesAttachmentUpload}
              age={ageAttachmentUpload}
              setAge={setAgeAttachmentUpload}
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              style={style}
              uploadCount={uploadCount}
              numAcceptedFiles={numAcceptedFiles}
              rejected_uploads_state={rejectedUploads}
              failed_uploads_state={failedUploads}
              setTable={setTable}
            />
          } />
        </Route>
      </Routes>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 9} onClose={handleAlertClose} autoHideDuration={6000} >
        <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
          <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            Your token had expired. Please login again. 
          </Alert>
      </Snackbar>

      <ConfirmDialog
        title={dialogState.title}
        question={dialogState.question}
        note={dialogState.note}
        files={dialogState.files}
        open={dialogState.open}
        upload_callback = {dialogState.upload_callback}
        reject_callback={dialogState.reject_callback}
        setOpen={setDialogState}
      />
    </div>
  );
}
export default App;