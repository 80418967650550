const DataUsageAgreementInstructions = (props) => {
    return (
        <div class="dua-instructions" style={{ 'margin-bottom': '0.5em', 'margin-top': '0.5em', fontSize: '13px' }} className="tab">
            <h4>Adding a Data Use Agreement (DUA) for the Cardiac Atlas Project (CAP) Database (Updated 12-2024)</h4>        
            <section class="overview">
                <h5><u>Overview</u></h5>
                <p style={{ 'margin-bottom': '0.5em', 'margin-top': '0.5em'}}>
                All non-UCSD and RCHSD Users of the Cardiac Atlas Database (<a href="https://capchd.ucsd.edu" target="_blank">capchd.ucsd.edu</a>)
                must have prior authorization to view, upload, and/or download the data. This authorization consists of a fully executed DUA
                (signed by both parties and uploaded to the UCSD Kuali system as an Unfunded Agreement with UCSD). The CAP database webpage
                allows for tracking current DUA agreements, with these instructions for adding new agreements. Once a DUA is executed, the user
                can be added to the database, allowing for access via that DUA.
                </p>
            </section>
            
            <section class="dua-template">
                <h5><u>DUA template</u></h5>
                <p>
                When a prospective user requests access to the CAP database, UCSD provides them with a pre-approved DUA template (Word file). This
                file is accessible below. UCSD will email this file to the user, asking them to fill out the highlighted information, sign, and
                send it back to UCSD. If the outside entity wants to change any of the terms or language in the template, that is fine, but the UCSD
                legal department will need to review, potentially requiring a longer legal interaction.
                </p>
            </section>
            
            <section class="kuali-request">
                <h5><u>Kuali Request</u></h5>
                <p>
                After the outside user has completed the DUA template, a new Kuali request is generated by UCSD personnel. Specific instructions for
                the Kuali request:
                </p>
                <ol className='tab' style={{'list-style-type': 'decimal'}}>
                    <li>The new Kuali document can be created by replicating an existing document. XXX</li>
                    <li>TBD</li>
                    <li>TBD</li>
                    <li>Upload the signed DUA as an attachment in Kuali</li>
                    <li>
                        After the DUA has been submitted via Kuali, send an email to our project manager, 
                        <a href="mailto:dariggs@ucsd.edu">Darin Riggs (dariggs@ucsd.edu)</a>, letting him know that a new DUA request has been uploaded.
                    </li>
                </ol>
                <p>The main Kuali Certification for this process is recorded as:</p>
                <ul className='tab'>
                    <li><strong>Document Number:</strong> 1246516</li>
                    <li><strong>Proposal Number:</strong> 62008</li>
                    <li><strong>Proposal Title:</strong> Data Use Agreement for The Cardiac Atlas Project</li>
                    <li><strong>Principal Investigator:</strong> Jeffrey Omens</li>
                    <li><strong>Lead Unit:</strong> 000239 - Bioengineering</li>
                    <li><strong>Sponsor:</strong> 6778 - UC SAN DIEGO</li>
                </ul>
            </section>
            <section class="approval">
                <h5><u>Approval</u></h5>
                <p>
                Once UCSD has approved the Kuali project request, the DUA is “fully executed,” and the new user/password can be added to the
                database by an admin. The executed request and other information should be added here.
                </p>
            </section>
        </div>
    )
}

export default DataUsageAgreementInstructions;