import { Divider } from "@material-ui/core";

import DetailsDropdown from "../DetailsDropdown.js";
import DataUsageAgreementUpload from "./DataUsageAgreementUpload.js";
import { DataUsageAgreementTable } from "./DataUsageAgreementTable.js";
import DUATemplateDownload from "./DUATemplateDownload.js";
import DataUsageAgreementInstructions from "./DataUsageAgreementInstructions.js";

const DataUsageAgreementPage = (props) => {
  return (
    <div style={{
      backgroundColor: 'rgb(235, 239, 241)',
    }}>
      <DetailsDropdown isHeader={true} title="Click here for instructions">
        <DataUsageAgreementInstructions/>
      </DetailsDropdown>

      <Divider style={{
        width: "100%",
        marginTop: "2rem",
        marginBottom: "2rem",
      }} />

      <div style={{}}>
        <DataUsageAgreementTable
          handleListItemClick={props.handleListItemClick}
          setEditing={props.setEditing}
          setOpenAlert={props.setOpenAlert}
          userGroup={props.userGroup}
          authToken={props.authToken}
          logoutCallback={props.logoutCallback}
          handleLinking={props.handleLinking}
        />
      </div>

      <Divider style={{
        width: "100%",
        marginTop: "2rem",
        marginBottom: "1rem",
      }} />
      
      <div style={{
        backgroundColor: 'rgb(235, 239, 241)',
        width: '100%',
        margin: 0,
        padding: 0,
      }}>
        <DataUsageAgreementUpload
          authToken={props.authToken}
          logoutCallback={props.logoutCallback}
          baseUrl={props.baseUrl}
          bucketName={props.bucketName}
          uploadToS3={props.uploadToS3}
          setOpenAlert={props.setOpenAlert}
          tableNames={props.tableNames}
          patientID={props.patientID}
          setPatientID={props.setPatientID}
          validID={props.validID}
          setValidID={props.setValidID}
          ages={props.ages}
          setAges={props.setAges}
          age={props.age}
          setAge={props.setAge}
          getInputProps={props.getInputProps}
          getRootProps={props.getRootProps}
          style={props.style}
          uploadCount={props.uploadCount}
          numAcceptedFiles={props.numAcceptedFiles}
          rejected_uploads_state={props.rejected_uploads_state}
          failed_uploads_state={props.failed_uploads_state}
          setTable={props.setTable}
        />
      </div>

      <Divider style={{
        width: "100%",
        marginTop: "2rem",
        marginBottom: "0.5rem",
      }} />

      <DUATemplateDownload />
    </div>
  );
}

export default DataUsageAgreementPage;