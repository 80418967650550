import React from "react";
import { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { Select } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import MDEditor from '@uiw/react-md-editor';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import {urls} from "../constants";
import { StudyMarkdown } from "./StudySummary";

const base_url = urls.base_url
const study_summary_url = `${base_url}/study_summary`;

export function StudySummaryPageEditor(props) {
    const [studySummaryList, setStudySummaryList] = useState([]);
    const [link, setLink] = useState(undefined);
    const [origMarkdown, setOrigMarkdown] = useState("");
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(study_summary_url, {headers:{}})
        .then(response => response.json())
        .then(data => {
            setStudySummaryList(data.sort((a, b) => a["ID"] - b["ID"]));
        }).catch(err=>{
            console.log("Failed to fetch study summary:", err);
        }, [])
    }, []);

    const handlePageSelect = (event) => {
        let link = event.target.value;
        setLink(link);
        console.log("Retrieving page using link: " + link);

        fetch(study_summary_url+`/${link}`, {headers:{}})
        .then(response => response.json())
        .then(data => {
            console.log("Retrieved page: " + data);
            setOrigMarkdown(data);
            setMarkdown(data);
        })
        .catch(err=>{
            console.log("Failed to retrieve page:", err);
        }, [])
    };

    const handleCancelClick = () => {
        // on cancel, reset to original/saved markdown
        setMarkdown(origMarkdown);
    };

    const handleSaveClick = () => {
        // send call to update backend with changes
        fetch(study_summary_url+`/${link}`, {
            method: 'POST',
            headers:{
                'Authorization': props.authToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"markdown":markdown}),
        })
        .then(response => {
            // on success, update original/saved markdown with new changes
            setOrigMarkdown(markdown);
        })
        .catch(err=>{
            console.log("Failed to post request:", err);
        }, [])
    };

    return (
        <div data-color-mode="light" style={{ height: "auto", width: "100%", marginBottom: 30 }} >
            <h3 className="fs-3">Study Summary Editor</h3>
            <Select inputProps={{fontSize: 16}} 
                    variant="filled" 
                    style={{fontSize: 16, width: 300, height: 50, backgroundColor: 'white', border: 3, borderColor: 'black',
                    color: "black",
                    "& .MuiSvgIcon-root": {
                        color: "black",
                        fontSize: 16
                    }}} 
                    labelId='page' 
                    onChange={handlePageSelect} 
                    value={link}>
                    {studySummaryList.map(studySummary => (
                        <MenuItem style={{fontSize: 16}} value={studySummary["Link"]}>
                            {studySummary["Header"]}
                        </MenuItem>
                    ))}
            </Select>
            <Typography>ID Dropdown will not work in preview.</Typography>
            <hr/>
            <MDEditor height={350} value={markdown} onChange={setMarkdown} 
                components={{preview: StudyMarkdown}}/>
            {link ? <>
                <Button variant="outlined" color="error" startIcon={<CancelIcon />}
                    onClick={handleCancelClick}>Cancel</Button>
                <Button variant="outlined" startIcon={<SaveIcon />}
                    onClick={handleSaveClick} disabled={markdown === origMarkdown}>Save</Button>
            </>
            : <></>}
        </div>
    )
}